
  iframe{
      height: 100%;
      width:100%;
  }
.container{
    background-color:transparent;
    position: absolute;
    left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
    height: 654px; 
    width: 1074px;
   margin:'auto';
    text-align:'center';
    display:'block';
}  

.wrapper{
    width:100%;
    height:32px;
    display:'flex'; 
    top:0; 
    background-color: transparent;
}
.warning-wrapper{
    background-color:#FCECCF; 
    height: 100%; 
    flex:1; 
    align-items: center;
    display: flex;
}
.warning-text{
    text-align: center; 
    align-self: center;
    margin: auto;
    color: #333333; 
    display: flex; 
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
}
.ds-container{
 height:500px;
 width:1014px;
 background-color: #333333;
 overflow: auto;
 flex: 1 1 auto;
    display: flex;
}
.ds-webview{
    border-width:0;
    height:664px;
    width: 1024px; 
    display: flex; 
    align-self: center;
}
.close-img{
    margin-left:16px;
    top:-2px;
}
img:hover {
    cursor: pointer;
}