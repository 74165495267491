.update-address{
    margin-left: 16px;
    margin-top:24px;
}
.btn-layout{
    display:flex;
    align-items: center;
    margin-top: 32px;
    margin-right:auto;
    margin-left:auto;
}
.radio-input{
    width: fit-content;;
    align-self:center;
    display: inline-block;
    margin-left: 16px;
    outline: none;
}
.address-list-container{
height: 50px;
  display: flex;
  align-items: center;
}
.address-labels{
    display:inline-block;
    margin-left: 16px;
    align-self:center;

}
.address-type{
    font-family: Roboto;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;

}
.address{
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;

}
h2{
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
}
.address-selection-layout{
    margin-top: 32px;
    height:100px;
    overflow: auto;
}
.address-selection-layout::-webkit-scrollbar {
    -webkit-appearance: none;
}

.address-selection-layout::-webkit-scrollbar:vertical {
    width: 11px;
}

.address-selection-layout::-webkit-scrollbar:horizontal {
    height: 11px;
}

.address-selection-layout::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #979797;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;

  }
  
  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #7DC452;
    border: 1px solid #7DC452;
    cursor: pointer;


  }
  