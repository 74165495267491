.info-label {
  margin-left: 13px;
  font-family: Roboto;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 8px;
  overflow: hidden;
  display: block;
}
.info-layout {
  margin-top: 8px;
  max-height: 78px;
  margin-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #cdcfd2;
}
.close-img {
  margin-left: 10px;
  position: relative;
}
.btn-layout {
  float: left;
  display: block;
  overflow: auto;
}
.top-layout {
  display: block;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-color: #cdcfd2;
  border-bottom-color: #cdcfd2;
  border-top-style: solid;
  border-bottom-style: solid;
  overflow: auto;
}

.login-btn {
  color: #ffffff;
  font-family: Roboto;
  font-weight: 500;
}
.login-layout {
  margin-top: 37px;
  margin-bottom: 37px;
}
button {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 13px;
}
div div {
  margin-top: 0px;
}

.name-column-title {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  width: 32%;
  padding-left: 80px;
}
.record-count-column-title {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  width: 13%;
  min-width: 85px;
  text-align: right;
}
.last-update-column-title {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-right: 24px;
  margin-left: 60px;
  width: 11%;
  min-width: 75px;
  text-align: right;
}
.name-column-container {
  width: 32%;
  display: flex;
}
.description-column-container {
  width: 28%;
  display: flex;
  overflow: visible;
}
.list-number {
  margin-right: 0px;
  padding: 0px;
  margin-left: 16px;
  clear: both;
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  width: 16px;
  text-align: right;
}
.batch-disabled{
  pointer-events: none;
  opacity: .5;

}
.name-column-value:hover {
  cursor: pointer;
}
.name-column-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  margin-left: 48px;
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  flex: 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
}
.description-column-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  width: 100%;
  flex:1;
}
.error-column-value {
  white-space: nowrap;
  overflow: visible;
  font-family: Roboto;
  font-weight: 500;
  color: #D0021B;
  font-size: 14px;
  text-align: right;
  width:30px;
  margin-left:5px;
  cursor: pointer;
  text-decoration: underline;
  z-index: 2;
}
.record-count-column-value {
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  width: 13%;
  min-width: 85px;
  text-align: right;
}
.last-update-column-value {
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  margin-right: 24px;
  margin-left: 60px;
  width: 11%;
  min-width: 75px;
  text-align: right;
}
.pending {
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  color: #D0021B;
  margin-right: 24px;
  margin-left: 60px;
  width: 11%;
  min-width: 75px;
  text-align: right;
}
.description-column-title {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  width: 28%;
}
.no-location {
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
img:hover {
  cursor: pointer;
}
.no-list {
  margin-top: 58px;
  color: #333333;
  font-family: Roboto;
  font-weight: 300;
  display: block;
  text-align: center;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 16px;
  top: 0px;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 3px;
  left: 20px;
  font-size: 15px;
  line-height: 0.8;
  color: black;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  pointer-events: none;
  opacity: .5;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
.checkbox-empty {
  position: "absolute";
  background-color: blue;
  color: green;
  width: 0px;
  padding: 0px;
}
