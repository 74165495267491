@import '../node_modules/@skyux/theme/css/sky.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap');
body{
    min-height: 100px;
    display: block;
    background-color: transparent;
    min-width: 710px;
    overflow-y: hidden;
}
button {
    display: block
}
.center-horizontal {
    display: block;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
  }
.name{
    resize: horizontal;
    width: 300px;
    display: block;
    margin-left: 15px;
    margin-top: 12px;
    flex:1;
}
.state-code{
    width: 60px;
    display: block;
    margin-left: 15px;
    margin-top: 12px;
}
.browser-msg{
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    margin-top:50px;
    margin-bottom: 50px;
    margin-left: 16px;
}
.browserList{
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    margin-top:50px;
    margin-bottom: 50px;
    margin-left: 20px;
    font-style: italic;
    padding: 8px;
    margin-top: 19px;
}
