.message {
  height: 36.8px;
  width: 456px;
  color: #333333;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  justify-content: "center";
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.popup-container {
  height: 100%;
  width: 100%;
  background-color: #33333310;
}
.popup-body-container {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  justify-content: "center";
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  position: absolute;
  margin-bottom: auto;
  height: 200px;
  width: 504px;
  background-color: white;
  border-top-width: 4px;
  border-top-color: #2e74a1;
  border-top-style: solid;
  display: flex;
}
.popup-img-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.popup-close {
  height: 12px;
  width: 12px;
  margin-right: 11px;
  margin-top: 11px;
}
