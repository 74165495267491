.address-label{
    margin-left:13px;
    font-family: Roboto;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 8px;
    overflow: hidden;
    display: block;

}
.address-layout{
    margin-top:8px;
    max-height: 78px;
    margin-bottom: 10px;
    width:100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #CDCFD2;
}
.close-img{
    margin-left: 10px;
    position: relative;
}
.address-text{
    font-weight: 600;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;

}
.btn-layout{
    float: left;
    display: block;
    overflow: auto;
}
.top-layout{
    display:block;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-color: #CDCFD2;
    border-bottom-color: #CDCFD2;
    border-top-style: solid;
    border-bottom-style: solid;
    overflow: auto;
}
.last-updated{
    margin-right:24px;
    float: right;
    display:block;
    overflow: hidden;

}
.last-updated-date{
    display:block;
    color: #333333;
    font-family: Roboto;
    font-weight: 400;
    text-align: end;
    font-size: 16px;

}
.last-updated-pending{
    display:block;
    color: #D0021B;
    font-family: Roboto;
    font-weight: 400;
    text-align: end;
    font-size: 16px;
}

.last-updated-text{
    margin-top: 8px;
    display:block;
    color: #333333;
    font-family: Roboto;
    font-weight: 300;
    font-size: 14px;

}
.login-btn{
    color: #ffffff;
    font-family: Roboto;
    font-weight: 500;
}
.login-layout{
    margin-top: 37px;
    margin-bottom: 37px;
}
.no-updates{
    color: #333333;
    font-family: Roboto;
    font-weight: 300;
    display:block;
    margin-top:50px;
    margin-bottom: 50px;
    text-align: center;
}
button{
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 13px;
}
div div{
    margin-top: 0px;
}
.attribute-row{
    text-align:left;
    display: block;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    color:#333333;
    margin-left: 16px;
}
.value-row{
    display: inline-block;
    text-align: right;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color:#333333;
    margin-right: 23px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width:50%;
}
.main-attribute{
    font-family: Roboto;
    font-weight: 300;
    font-size: 16px;
    color:#333333;
    margin-left: 16px;
}
.main-value{
    font-family: Roboto;
    font-weight: 300;
    font-size: 16px;
    color:#333333;
    margin-right: 23px;
    width: 50%;
}
.no-location{
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color:#333333;
}
img:hover {
    cursor: pointer;
}