@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.wrapper {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center {
    text-align: center;
}

.result-text {
    color: #333333;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin: 40px 0;
}

.info-text {
    color: #333333;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}