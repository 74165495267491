.error-popup-top-spacer{
    width:720px;
    height: 24px;
    background-color: white;
    padding:0px;
    border:none;
    border-bottom: none;
}
.error-popup-bottom-spacer{
    width:720px;
    height: 12px;
    background-color: white;
    padding:0px;
    border:none;
    border-top: none;
}
.error-popup-container{
    width:720px;
    height: 396px;
    background-color: white;
    display: block;
    overflow: auto;
    overflow-y: overlay;
    padding:0px;
    border-bottom: none;

}
.batch-popup-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 26px;
    border-bottom: 1px solid #ccc;
    height:44px;
}
.batch-error-popup-list-name{
  font-family: Roboto;
  font-weight: 300;
  color: #3A3A3A;
  font-size: 21px;
  margin-top: 2px;
  line-height: 24px;
  width: 405px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
}
.close-error-msg{
    font-family: Roboto;
    margin-left: 42px;
    color:#333333;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
}
.close-popup{
    position: absolute;
    right:0px;
    top: 0px;
}
.batch-donor-list-container{
    margin-top: 16px;
    width:100%;
    word-wrap:break-word;
}
.batch-donor-name{
    color:#2E74A1;
    //cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    text-decoration: underline;
    width:133px;
    white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  white-space:nowrap;
  cursor: pointer;

}
.batch-donor-name-container{
    word-wrap:break-word;
    margin-bottom: 16px;
    margin-left: 24px;
    display: flex;
}
.batch-error-msg{
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    display: block;
    color:#D0021B;
    font-size: 14px;
    margin-top: 170px;
    font-family: Roboto;
    font-weight: 500;
}
.batch-error-donor-msg{
    color:#D0021B;
    font-size: 14px;
    margin-left: 16px;
    font-family: Roboto;
    font-weight: 500;
    display: flex;
    flex:1;
    margin-right: 24px;

}
.disabled{
    pointer-events: none;
    opacity: .5;
}
.checkbox-close-container{
    margin-top: 0px;
}


[type="checkbox"]:not(:checked):required + label:before,
[type="checkbox"]:checked:required + label:before {
  content: "";
  position: absolute;
  left: 16px;
  top: 0px;
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}


