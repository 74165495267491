.wait-container{
    margin-top: 0px;
          top: 0px;
          left:0px;
          right:0px;
          display: flex;
          height: 100%;
          width: 100%;
          position: fixed;
          align-items: center;
          justify-content: center;
          vertical-align: top;
}
.block-out-container{
    background-color: white;
            display: flex;
            height: 100%;
            width: 100%;
            position: fixed;
            vertical-align: top;
}