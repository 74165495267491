.item-container{
    height: 32px;
    width: 26px;
    background-color: white;
    border-width: 0.5px;
    border-left-width: 0px;
    border-color: #E2E3E4;
    border-style: solid;
    align-items: center;
    display: flex;
}
.left-arrow-container{
    border-left-width: .5px;

}
.item{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color:#333333;
}
.arrow-img{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 9px;
}
.paging-container{
    display: flex;
    flex:1;
    width:100%;
}
.number-container{
    display: flex;
}
.item-container:hover{
    cursor: pointer;

}
.item:hover{
    cursor: pointer;
}
.item-container:hover{
    background-color: #D4D4D6;
}