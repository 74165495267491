.footer{
    width:100%;
    position: fixed;
    bottom: 8px;
    right:0px;
    align-self: flex-end;
    justify-content: baseline;
}
.version{
    display:block;
    margin-right:24px;
    color: #333333;
    font-family: Roboto;
    font-weight: 300;
    font-size: 14px;  
    text-align: right; 
}